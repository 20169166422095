import axios from "axios";

export default {
    state: {
        paymentSystems: [],
        toUpBalanceResponse: null
    },
    mutations: {
        setterPaymentSystems(state, data) {
            state.paymentSystems = data;
        },
        setterToUpBalanceResponse(state, data) {
            state.toUpBalanceResponse = data;
        }
    },
    getters:{
        getPaymentSystems(state) {
            return state.paymentSystems;
        },
        getToUpBalanceResponse(state) {
            return state.toUpBalanceResponse;
        }
    },
    actions: {
        async getPaymentSystems(context) {
            await axios.get('/user-dashboard/payment-systems').then((response) => {
                context.commit('setterPaymentSystems',response.data);
            });
        },
        async createPayment(context, data) {
            await axios.post('/user-dashboard/balance/create-payment' ,data).then((response) => {
                context.commit('setterToUpBalanceResponse',response.data);
            });
        },
        async createWholesalerPaymentLink(context, data) {
            await axios.post('/user-dashboard/wholesaler/create-payment-link', data).then((response) => {
                //context.commit('setterToUpBalanceResponse', response.data);
            });
        }
    }
};
