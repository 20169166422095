import './bootstrap';
import { createApp } from 'vue';
import { defineAsyncComponent } from 'vue';
import {i18n} from '../admin/i18n';
import store from '../store/frontend';
import 'toastr/build/toastr.min.css';
import mitt from "mitt";
const emitter = mitt();

const app = createApp({
    components: {
        StoresListComponent: defineAsyncComponent(() => import('./components/store/StoresListComponent.vue')),
        CalculatorComponent: defineAsyncComponent(() => import('./components/home/CalculatorComponent.vue')),
        DashboardSettingsAccountComponent: defineAsyncComponent(() => import('./components/dashboard/forms/settings/AccountComponent.vue')),
        DashboardSettingsAddressComponent: defineAsyncComponent(() => import('./components/dashboard/forms/settings/AddressComponent.vue')),
        DashboardSettingsChangePasswordComponent: defineAsyncComponent(() => import('./components/dashboard/forms/settings/ChangePasswordComponent.vue')),
        RegisterOrderComponent: defineAsyncComponent(() => import('./components/dashboard/packages/RegisterOrderComponent.vue')),
        ExceptedOrdersComponent: defineAsyncComponent(() => import('./components/dashboard/packages/ExceptedOrdersComponent.vue')),
        InWarehouseOrdersComponent: defineAsyncComponent(() => import('./components/dashboard/packages/InWarehouseOrdersComponent.vue')),
        TopUpBalanceComponent: defineAsyncComponent(() => import('./components/dashboard/forms/TopUpBalanceComponent.vue')),
        TransactionsListComponent: defineAsyncComponent(() => import('./components/dashboard/TransactionsListComponent.vue')),
        PackagingParcelComponent: defineAsyncComponent(() => import('./components/dashboard/packages/PackagingParcelComponent.vue')),
        FormableParcelsComponent: defineAsyncComponent(() => import('./components/dashboard/packages/FormableParcelsComponent.vue')),
        AwaitingShipmentParcelsComponent: defineAsyncComponent(() => import('./components/dashboard/packages/AwaitingShipmentParcelsComponent.vue')),
        ParcelComponent: defineAsyncComponent(() => import('./components/dashboard/packages/ParcelComponent.vue')),
        ParcelsListComponent: defineAsyncComponent(() => import('./components/dashboard/packages/ParcelsListComponent.vue')),
        CreatePurchaseComponent: defineAsyncComponent(() => import('./components/dashboard/forms/CreatePurchaseComponent.vue')),
        EntrustListComponent: defineAsyncComponent(() => import('./components/dashboard/entrusts/ListComponent.vue')),
        EntrustItemComponent: defineAsyncComponent(() => import('./components/dashboard/entrusts/ItemComponent.vue')),
        SwiperComponent: defineAsyncComponent(() => import('./components/partials/SwiperComponent.vue')),
        MailList: defineAsyncComponent(() => import('./components/mail/ListComponent.vue')),
        ReviewFormComponent: defineAsyncComponent(() => import('./components/reviews/ReviewFormComponent.vue')),
        WholesalerComponent: defineAsyncComponent(() => import('./components/dashboard/WholesalerComponent.vue')),
        CreateWholesalerPaymentLinkComponent: defineAsyncComponent(() => import('./components/dashboard/forms/CreateWholesalerPaymentLinkComponent.vue')),
    }
});

app.config.compilerOptions.isCustomElement = tag => tag.startsWith('x-');

app.use(i18n);
app.use(store);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
